import React, { useEffect } from 'react';
import { CognitoUser } from '@aws-amplify/auth';
import { Text } from '@aws-amplify/ui-react';
import styled from '@emotion/styled';

type PostLoginProps = {
  user: CognitoUser;
};

const sendAuthMessage = (user: CognitoUser) => {
  if (window.top && user.getSignInUserSession) {
    window.top.postMessage(user.getSignInUserSession(), '*'); // TODO: Restrict Domain
  }
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 600px;
`;

const PostLogin: React.FC<PostLoginProps> = ({ user }) => {
  useEffect(() => {
    console.info('Sending Iframe Message');
    sendAuthMessage(user);
  }, [user]);
  return (
    <Container>
      <Text>You've successfully registered into ArtNFT!</Text>
    </Container>
  );
};

export default PostLogin;
