import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AmplifyProvider, Theme } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';
import './index.css';

const theme: Theme = {
  name: 'my-theme',
  tokens: {
    colors: {
      brand: {
        primary: {
          '10': { value: '#ffb7b1' },
          '20': { value: '#ff8a7f' },
          '40': { value: '#ff5b4c' },
          '60': { value: '#ff2e1a' },
          '80': { value: '#ff2e1a' },
          '100': { value: '#210000' },
        },
      },
    },
  },
};

ReactDOM.render(
  <React.StrictMode>
    <AmplifyProvider theme={theme}>
      <App />
    </AmplifyProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
