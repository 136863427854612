import React from 'react';
import {
  Authenticator,
  useAuthenticator,
  CheckboxField,
  View,
  Image,
  Text,
  Link,
} from '@aws-amplify/ui-react';
import Auth from '@aws-amplify/auth';
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui';
import styled from '@emotion/styled';
import PostLogin from './PostLogin';
import Logo from './artnet.png';

I18n.putVocabularies({ en: translations['en'] });
I18n.setLanguage('en');

I18n.putVocabulariesForLanguage('en', {
  'Given Name': 'First Name',
  'Family Name': 'Last Name',
  'Confirm Sign Up': 'Confirmation code was sent to your email',
});

Auth.configure({
  region: 'us-east-1',
  userPoolWebClientId: '3ddsgbirtvr2t750b60aqcti57',
  userPoolId: 'us-east-1_9mRx6Peo2',
});

const Container = styled.div`
  margin-top: 24px;
`;

const App: React.FC = () => {
  return (
    <Container>
      <Authenticator
        initialState="signUp"
        loginMechanisms={['email']}
        signUpAttributes={['given_name', 'family_name']}
        components={{
          Header() {
            return (
              <View textAlign="center">
                <Image alt="Artnet Logo" src={Logo} maxWidth="180px" />
              </View>
            );
          },
          SignUp: {
            FormFields() {
              const { validationErrors } = useAuthenticator();

              return (
                <>
                  <Authenticator.SignUp.FormFields />
                  <CheckboxField
                    errorMessage={validationErrors.acknowledgement}
                    hasError={!!validationErrors.acknowledgement}
                    name="acknowledgement"
                    value="yes"
                    label={
                      <Text>
                        I agree with the{' '}
                        <Link
                          href="https://www.artnet.com/auctions/terms-of-use"
                          isExternal
                        >
                          {' '}
                          Terms & Conditions{' '}
                        </Link>{' '}
                      </Text>
                    }
                  />
                </>
              );
            },
          },
        }}
        services={{
          async validateCustomSignUp(formData) {
            if (!formData.acknowledgement) {
              return {
                acknowledgement: 'You must agree to the Terms & Conditions',
              };
            }
          },
        }}
      >
        {({ signOut, user }) => <PostLogin user={user} />}
      </Authenticator>
    </Container>
  );
};

export default App;
